@keyframes blink {
  0% {
    background: white;
  }
  25% {
    background: #ccc;
  }
  50% {
    background: white;
  }
  75% {
    background: #ccc;
  }
}

.MuiPaper-root.MuiCard-root.MuiPaper-rounded.snippet-exit {
  animation: blink 1s ease-in-out;
}
