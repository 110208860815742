@keyframes blink {
  0% {
    background: white;
  }
  25% {
    background: #ccc;
  }
  50% {
    background: white;
  }
  75% {
    background: #ccc;
  }
}

.patientTableCellRecord.MuiTableRow-root {
  background: linear-gradient(
      0deg,
      rgba(237, 245, 255, 0.3),
      rgba(237, 245, 255, 0.3)
    ),
    rgba(246, 246, 246, 0.84);
}

.patientTableCellRecord.MuiTableRow-root:nth-of-type(4n + 1),
.patientTableCellRecord.MuiTableRow-root:nth-of-type(4n - 2) {
  background: white;
}

.patientTableCellRecord.MuiTableRow-root.row-exit {
  animation: blink 1s ease-in-out;
}
